/////////////////////////
// Module Map
/////////////////////////

.site-main {
    &__map {
        height: 100%;
        width: 100%;
        display: flex;
        position: relative;

        .map {
            &-wrapper {
                height: 100%;
                flex-grow: 1;
                position: relative;
            }

            &-full-screen {
                position: absolute;
                top: calc(1rem + 12px); // matches the search bar
                left: 1rem;
                z-index: 9;

                button {
                    background: $color-white;
                    border-color: $color-white;

                    svg {
                        color: $color-black;
                    }

                    &:hover {
                        background: $color-white;
                        border-color: $color-white;
                    }
                }
            }

            &-container {
                height: 100%;
                flex-grow: 1;
                position: relative;

                &.with-bottom-panel {
                    height: calc(100% - 300px);
                }
            }

            &-bottom-panel {
                height: 300px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                flex-direction: row;

                .map-chart {
                    width: 100%;
                    flex-grow: 1;
                }

                &-sidebar-expanded {
                    width: 100%;
                }
            }

            &-bottom-panel-controls {
                display: flex;
                flex-shrink: 0;
                padding: .25rem;
                background: #222529;
            }

            &-bottom-panel-select {
                margin: 8px;
            }

            &-toggle {
                width: 29px;
                height: 29px;
                background: $color-white;
                @include radius(.25rem);
                position: absolute;
                top: 49px;
                left: 10px;
                box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                user-select: none;



                svg {
                    height: 1.25rem;
                    width: auto;
                    fill: #333;
                }

                &.active {
                    left: 410px;

                    @include media-breakpoint-down(md) {
                        z-index: 10;
                        right: 1rem;
                        left: auto;
                        top: 1.5rem;
                    }
                }
            }

            &-sidebar {
                height: 100%;
                display: flex;
                flex-shrink: 0;
                position: relative;
                z-index: 1;

                .sidebar {
                    &-icons {
                        width: 3rem;
                        background: var(--background-primary-dark);
                        overflow-x: hidden;
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            width: 0.25rem;
                        }

                        &::-webkit-scrollbar-track {
                            background: none;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $gray-600;
                            outline: none;
                            @include radius(1rem);
                        }

                        ul {
                            list-style: none;
                            margin: 0;
                            padding: 0;

                            li {
                                button {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: none;
                                    border: none;
                                    box-shadow: none;
                                    outline: none;
                                    width: 3rem;
                                    height: 3rem;
                                    color: $color-white;
                                    @include transition($transition);

                                    svg {
                                        height: 1.5rem;
                                        width: auto;
                                    }

                                    &:hover,
                                    &.active {
                                        background: $color-brand;
                                    }

                                    &:disabled {
                                        opacity: 0.5;
                                    }
                                }

                                &:not(:first-child) {
                                    border-top: solid 1px rgba($color-white, .1);
                                }
                            }
                        }
                    }

                    &-content {
                        width: 400px;
                        background: var(--neutral-800);

                        @include media-breakpoint-down(md) {
                            width: calc(100vw - 3rem);
                        }

                        .table {
                            tbody {
                                td {
                                    border-bottom-color: $gray-600;
                                }
                            }

                            @include media-breakpoint-down(md) {
                                & {
                                    tbody {
                                        tr {
                                            border-color: $gray-600;
                                        }
                                    }
                                }
                            }
                        }

                        &-wide {
                            width: 600px;
                            background: var(--neutral-800);
                        }
                    }

                    &-title {
                        height: 4rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 calc(2rem + 34px);
                        position: relative;
                        border-bottom: solid 1px rgba($color-white, .1);

                        button {
                            position: absolute;
                            top: 50%;
                            @include translate(0, -50%);
                            left: 1rem;
                        }

                        h4 {
                            @include ellipsis;

                            &+button {
                                left: auto;
                                right: 1rem;
                            }
                        }
                    }

                    &-inner {
                        @include font__size(13px);
                        @include line__height(20px);
                        padding: 1.5rem 1rem;
                        height: calc(100% - 4rem);
                        overflow-x: hidden;
                        overflow-y: auto;
                        z-index: 1;
                        position: relative;

                        &::-webkit-scrollbar {
                            width: 0.25rem;
                        }

                        &::-webkit-scrollbar-track {
                            background: none;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $gray-600;
                            outline: none;
                            @include radius(1rem);
                        }

                        .scroll {
                            overflow-x: hidden;
                            overflow-y: auto;

                            &::-webkit-scrollbar {
                                width: 0.25rem;
                            }

                            &::-webkit-scrollbar-track {
                                background: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: $gray-600;
                                outline: none;
                                @include radius(1rem);
                            }

                            &-events {
                                max-height: calc(100% - 209px);
                            }

                            &-alerts {
                                max-height: calc(100% - 78px);
                            }

                            &-map-tasks {
                                max-height: calc(100% - 500px);
                            }
                        }
                    }
                }
            }

            &-range {
                position: fixed;
                right: 3rem; // Sidebar Gap
                left: 0;
                bottom: 20px;
                z-index: 3;

                &-sidebar-expanded {
                    width: calc(100% - 600px - 3rem);
                    margin-right: 600px;
                }
            }
        }

        // DATE TIME SLIDER
        .DateTimeSlider {
            margin: 0 !important;
            width: calc(100% - 4rem);
            bottom: 3rem;
            left: 50%;
            @include translate(-50%, 0);
        }
    }
}

// MAPBOX 

.mapboxgl-ctrl {
    &.mapboxgl-ctrl-attrib {
        background: none;

        .mapboxgl-ctrl-attrib {
            &-button {
                display: none;
            }

            &-inner {
                a {
                    color: $color-white;

                    &:hover {
                        color: $color-brand;
                    }
                }
            }
        }

        &.mapboxgl-compact {
            display: none !important;
        }
    }

    &-top-right {
        .mapboxgl-ctrl {
            @include radius(4px);
        }
    }

    &-geocoder--icon-search {
        display: none !important;
    }

    &-geocoder {
        width: 300px;
    }
}

.mapboxgl-popup {
    z-index: 10;

    & *:not(.button, .custom-color, svg, path) {
        color: $gray-900 !important;
    }

    hr {
        margin: .5rem 0;
        border-color: rgba($gray-900, .25);
        border-width: 1px;
    }

    &-content {
        font-family: $font-primary;
        @include font__size(13px);
        @include line__height(20px);
        font-weight: $font-weight--400;
        color: $gray-900;
        min-width: 240px;
        padding: 10px;

    }

    &-close-button {
        @include font__size(24px);
    }
}

// Modal MAPBOX

.map-shape {
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 500px;
    background: $gray-900;
}